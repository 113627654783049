import React from 'react';
import EventList from '../../components/EventList/EventList';

const Events = () => {
  return (
    <div>
      {/* <h1>Події</h1> */}
      <EventList />
    </div>
  );
};

export default Events;
