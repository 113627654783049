// OfferPage.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const OfferPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        ДОГОВІР
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        на реалізацію квитків на культурно-видовищні заходи (публічна оферта ФОП
        Черепанова О.О.)
      </Typography>

      <Box sx={{ my: 2 }}>
        <Typography variant="body1" paragraph>
          Стаття 1. ВИЗНАЧЕННЯ
        </Typography>
        <Typography variant="body2" paragraph>
          У цій Публічній оферті на реалізацію квитків на культурно-розважальні
          заходи (далі - "Оферта") наведені нижче терміни та визначення
          використовуються рівно в однині та множині, у наступних значеннях:
        </Typography>

        <Typography variant="body2" paragraph>
          1.1. "Оферта" - ця Публічна оферта на реалізацію квитків на
          культурно-розважальні заходи (Публічна оферта ФОП Черепанова О.О.),
          опублікована на інтернет-сайтах, які належать та/або знаходяться в
          оренді у АГЕНТА (інтернет-сайтах Агента), за електронною адресою в
          мережі Інтернет:
          <a
            href="http://toptickets.com.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://toptickets.com.ua/
          </a>
          .
        </Typography>
        <Typography variant="body2" paragraph>
          1.2. "Принципал" - юридична особа, яка поручає Агенту від імені
          Принципала та/або Організатора за його рахунок надавати послуги з
          розповсюдження (між існуючими у Агента субагентами) та бронювання,
          оформлення та реалізації (безпосередньо Покупцям) Квитків на Заходи,
          якщо інше прямо не передбачено положеннями цієї Оферти;
        </Typography>
        <Typography variant="body2" paragraph>
          1.3. "Агент" - ФОП Черепанова О.О., уповноважена особа на підставі
          укладених з Принципалами Договорів надавати послуги з розподілу,
          бронювання, оформлення та реалізації Квитків на різні Заходи;
        </Typography>
        <Typography variant="body2" paragraph>
          1.4. "Покупець" - фізична або юридична особа (особи), якій Агентом на
          умовах, встановлених цією Офертою, надаються послуги з бронювання,
          оформлення та реалізації Квитків на Заходи;
        </Typography>
        <Typography variant="body2" paragraph>
          1.5. "Захід" - культурно-розважальний захід, включаючи театральний
          виступ, циркове шоу, концерт в залі, клубі або на відкритій площі,
          виставку, кінопоказ, фестиваль, шоу, показ мод, спортивні змагання,
          екскурсію, а також будь-яку іншу подію, відвідування якої можливе лише
          з пред'явленням спеціального документа - квитка;
        </Typography>
        <Typography variant="body2" paragraph>
          1.6. "Квиток" - документ, який засвідчує право Покупця на відвідування
          Заходу і містить всю необхідну інформацію про Захід, виділений Покупцю
          місце на Заході (сектор, ряд та інше, а також засвідчує право проходу
          на Захід) і вартість послуг;
        </Typography>
        <Typography variant="body2" paragraph>
          1.7. "Номінальна ціна квитка" - ціна відвідування Заходу, встановлена
          Принципалом для всіх Покупців;
        </Typography>
        <Typography variant="body2" paragraph>
          1.8. "Система" - інформаційна система, призначена для оформлення та
          реалізації Квитків.
        </Typography>
        <Typography variant="body2" paragraph>
          1.9. "Точка продажу" - офіс продажу Квитків, а також офіс продажу
          Квитків, який належить Агенту, або офіси продажу Квитків, які належать
          субагентам та партнерам Агента;
        </Typography>
        <Typography variant="body2" paragraph>
          1.10. "Сервісний збір" - грошові кошти, стягувані з Покупця при
          реалізації йому Квитка з Системи Агента за надані Агентом послуги з
          інформаційної підтримки Покупця через сайт, а також через інші канали
          зв'язку, бронювання, оформлення та реалізації Квитків. Величина
          Сервісного збору для кожного Квитка визначається Агентом за згодою з
          Принципалом або Агентом самостійно у відсотковому відношенні до
          Номінально
        </Typography>
        <Typography variant="body2" paragraph>
          1.11. «Замовлення» - один або кілька Квитків на одну Подію, обраних
          Покупцем (для Покупця) з Системи та об'єднаних в Системі єдиним
          ідентифікаційним номером;
        </Typography>
        <Typography variant="body2" paragraph>
          1.12. «Анулювання» - видалення Квитків з Замовлення з автоматичним
          перекладом їх у вільну продажу в Системі. Якщо Замовлення не оплачене,
          воно анулюється автоматично.
        </Typography>
        <Typography variant="body2" paragraph>
          1.13. «Сертифікат» - документ, що підлягає обміну на квиток.
        </Typography>
        <Typography variant="body2" paragraph>
          1.14. «Доставка» - комплексна послуга Агента з переміщення,
          перевезення та адресної доставки квитків отримувачу, що
          супроводжується інформуванням Замовника та відповідальністю за
          збереження відправлення.
        </Typography>
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="body1" paragraph>
          Стаття 2. ЗАГАЛЬНІ ПОЛОЖЕННЯ
        </Typography>
        <Typography variant="body2" paragraph>
          2.1. Ця Оферта регулює умови та порядок продажу Квитків та обов'язки,
          що виникають у зв'язку з цим у Агента та Покупця.
        </Typography>
        <Typography variant="body2" paragraph>
          2.2. Продаж Квитків Покупцеві здійснюється виключно на умовах цієї
          Оферти та лише у разі її повного та беззастережного прийняття.
          Часткове прийняття, а також прийняття на інших умовах не допускається.
          Використання Покупцем послуг Агента на запропонованих у цій Оферті
          умовах свідчить про укладення між ними договору.
        </Typography>
        <Typography variant="body2" paragraph>
          2.3. З метою придбання квитків у Агента юридичні особи здійснюють їх
          оплату за згодою з Агентом будь-якими не забороненими законодавством
          України способами. У цьому випадку діють Правила цієї Оферти, якщо
          інше прямо не встановлено в відповідному договорі між Агентом та
          юридичною особою.
        </Typography>
        <Typography variant="body2" paragraph>
          2.4. Агент має право в будь-який час вносити зміни до цієї Оферти, але
          у будь-якому випадку такі зміни публікуються та повідомляються до
          загального відома шляхом публікації на Інтернет-сайтах Агента за
          електронною адресою в мережі Інтернет:
          <a
            href="http://toptickets.com.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://toptickets.com.ua/
          </a>
          або в Точках продажу. Використання Покупцем послуг Агента після
          внесення змін до тексту цієї Оферти означає акцепт Оферти з
          урахуванням внесених змін.
        </Typography>
        <Typography variant="body2" paragraph>
          2.5. Умови цієї Оферти є чинними як для Покупців, що здійснюють оплату
          через Інтернет-сайти Агента:{' '}
          <a
            href="http://toptickets.com.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://toptickets.com.ua/
          </a>
          , так і в Точках продажу, і не залежать від форми оплати.
        </Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant="body1" paragraph>
          Стаття 3. ПРЕДМЕТ ОФЕРТИ
        </Typography>
        <Typography variant="body2" paragraph>
          3.1. Предметом цієї Оферти є надання Покупцю послуг з бронювання,
          оформлення, реалізації та доставки (останнє за додаткову плату,
          стягується окрім Сервісного збору) Квитків на Заходи, в порядку та на
          умовах, передбачених цією Офертою (далі за текстом - Послуги).
        </Typography>
        <Typography variant="body2" paragraph>
          3.2. Відповідальним за якість наданих Послуг перед Покупцем є Агент.
        </Typography>
        <Typography variant="body2" paragraph>
          3.3. Агент не несе відповідальності за дії Принципала і (або) інших
          осіб, що діють від його імені та за його дорученням або власним
          іменем, але за дорученням Принципала, включаючи реалізацію такими
          особами Квитків, а також за організацію, сам факт проведення і зміст
          Події.
        </Typography>
        <Typography variant="body2" paragraph>
          3.4. Відповідно до положень Закону України "Про захист персональних
          даних" № 2297-VI від 01.06.2010 р., Покупець визнає та погоджується з
          наданням Агенту своїх персональних даних, а також персональних даних
          третіх осіб, зазначених Покупцем, які отримані Агентом в процесі
          оформлення Квитка (Замовлення), а саме: прізвище, ім'я, стать,
          контактний номер телефону, місто, адреса доставки (у випадку надання
          послуг з доставки Квитків) та адреса електронної пошти. Обробка
          персональних даних Покупця здійснюється відповідно до законодавства
          України. Покупець надає Агенту право на обробку його персональних
          даних у зв'язку з наданням Покупцю Послуг, передбачених цією Офертою,
          зокрема для отримання Покупцем рекламних повідомлень про Події, квитки
          на які реалізуються Агентом. Оскільки обробка персональних даних
          Покупця здійснюється у зв'язку з виконанням договору, укладеного на
          умовах цієї Оферти, згода Покупця на обробку його персональних даних
          не потрібна. Термін використання наданих персональних даних -
          безстроковий.
        </Typography>

        {/* Additional sections continue here */}
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant="body1" paragraph>
          Стаття 4. ПРИЙНЯТТЯ ОФЕРТИ
        </Typography>
        <Typography variant="body2" paragraph>
          4.1. Прийняття Покупцем положень цієї Оферти здійснюється шляхом
          вчинення ним дій, зазначених у відповідному підпункті пункту 4.3. цієї
          Оферти (акцепт Оферти). Вчинення акцепту Покупцем цієї Оферти
          здійснюється добровільно і в повному обсязі.
        </Typography>
        <Typography variant="body2" paragraph>
          4.2. До вчинення акцепту Оферти Покупець повинен бути впевнений, що
          всі умови цієї Оферти зрозумілі йому і він приймає їх безумовно і в
          повному обсязі.
        </Typography>
        <Typography variant="body2" paragraph>
          4.3. Акцепт цієї Оферти Покупцем здійснюється також у випадку
          придбання Квитка через Інтернет, за телефоном або в Точці продажу
          шляхом послідовного вчинення всіх наступних дій: вибір Події,
          категорії Квитка, оформлення Замовлення, оплата Замовлення в повному
          обсязі.
        </Typography>
        <Typography variant="body2" paragraph>
          4.4. Вчинення всіх дій, зазначених у пункті 4.3. цієї Оферти, означає
          повне і безумовне прийняття Покупцем всіх умов Оферти без будь-яких
          винятків і (або) обмежень і рівносильне укладенню письмового договору
          (п.2 ст. 642 Господарського Кодексу України).
        </Typography>

        {/* Additional sections continue here */}
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant="body1" paragraph>
          Стаття 5. ПОРЯДОК ЗДІЙСНЕННЯ ПЛАТЕЖІВ
        </Typography>
        <Typography variant="body2" paragraph>
          5.1. Покупець має право здійснити оплату Квитків будь-яким з способів,
          запропонованих Агентом, інформація про які знаходиться на
          інтернет-сайтах Агента:
          <a
            href="http://toptickets.com.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://toptickets.com.ua/
          </a>
        </Typography>
        <Typography variant="body2" paragraph>
          5.2. Оплата вважається здійсненою Покупцем в момент надходження
          грошових коштів на розрахунковий рахунок Агента або до його каси, або
          каси його офіційних представників (включаючи субагентів).
        </Typography>
        <Typography variant="body2" paragraph>
          5.3. У разі здійснення платежу за допомогою банківської картки
          Покупець зобов'язаний використовувати банківську картку, видану на
          ім'я Покупця. Одночасно з цим Агент має право вимагати надання
          Покупцем оригіналів документів, що посвідчують особу Покупця. Платіж
          не приймається, а Замовлення анулюється у разі порушення Покупцем умов
          платежу, встановлених законодавством України.
        </Typography>
        <Typography variant="body2" paragraph>
          5.4. З дотриманням всіх умов цієї Оферти і після оплати Замовлення
          вважається проданим, і у Покупця виникає право відвідати Подію на
          вказаних в Квитку умовах.
        </Typography>

        {/* Additional sections continue here */}
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant="body1" paragraph>
          Стаття 6. ДОСТАВКА КВИТКІВ
        </Typography>
        <Typography variant="body2" paragraph>
          6.1. Передача квитків Покупцеві здійснюється наступними способами за
          вибором Покупця:
        </Typography>
        <Typography variant="body2" paragraph>
          - на електронну пошту покупця Агентом відправляється лист, у вкладенні
          якого знаходиться бланк електронного квитка з унікальним штрих-кодом,
          який потрібно роздрукувати. Зазначений бланк Квитка дає право Покупцю
          на відвідання обраної події та пред'являється при вході.
        </Typography>
        <Typography variant="body2" paragraph>
          - доставка Квитка кур'єрською службою за додаткову оплату такої
          послуги.
        </Typography>

        <Typography variant="body1" paragraph sx={{ mt: 2 }}>
          Стаття 7. АНУЛЮВАННЯ ЗАМОВЛЕННЯ
        </Typography>
        <Typography variant="body2" paragraph>
          7.1 Анулювання замовлення Покупця відбувається в наступних випадках:
        </Typography>
        <Typography variant="body2" paragraph>
          - відмова Покупця від оформлення Замовлення;
        </Typography>
        <Typography variant="body2" paragraph>
          - відсутність оплати протягом 15 хвилин після оформлення Замовлення за
          допомогою інтернет-еквайрингу;
        </Typography>
        <Typography variant="body2" paragraph>
          - відсутність оплати протягом 1 (одних) доби після оформлення
          Замовлення в разі, коли оплата повинна бути здійснена через термінал;
        </Typography>
        <Typography variant="body2" paragraph>
          - при замовленні кур'єрської доставки, кур'єр не зміг передати Квитки
          Покупцю через його відсутність за вказаною адресою.
        </Typography>

        <Typography variant="body1" paragraph sx={{ mt: 2 }}>
          Стаття 8. ПОВЕРНЕННЯ ГРОШОВИХ КОШТІВ
        </Typography>
        <Typography variant="body2" paragraph>
          8.1. Повернення Номінальної ціни Квитка Покупцеві здійснюється лише у
          разі скасування Події або перенесення термінів проведення Події (за
          винятком випадків, коли перенесення терміну проведення було
          непримусовою заходом у зв'язку з форс-мажорними обставинами).
        </Typography>
        <Typography variant="body2" paragraph>
          8.2. Повернення Сервісного збору Агентом не здійснюється, а також не
          підлягає поверненню вартість доставки Квитків, якщо послуга доставки
          була додатково замовлена Покупцем.
        </Typography>
        <Typography variant="body2" paragraph>
          8.3. Повернення грошових коштів, внесених Покупцем як оплата
          Номінальної ціни Квитка, у зв'язку з тим, що Агент при реалізації
          Квитків на Події діє від імені і за рахунок Принципала, здійснюється
          Принципалом самостійно або особою, ним вказаною, на умовах,
          встановлених цією статтею.
        </Typography>
        <Typography variant="body2" paragraph>
          8.4. Агент зобов'язується за вимогою Покупця надати йому інформацію
          про особу, відповідальну за повернення Номінальної ціни Квитка. У
          разі, якщо зазначена особа Агенту невідома, він зобов'язується за
          вимогою Покупця направити Принципалу відповідне звернення з вимогою
          вказати особу, відповідальну за повернення Номінальної ціни Квитка. У
          розумний строк після отримання відповіді від Принципала, але в
          будь-якому випадку не пізніше 10 (десяти) робочих днів з вказаного
          моменту Агент зобов'язаний надати копію відповіді Принципала
          Покупцеві. Положення цього пункту не стосуються права Покупця
          самостійно звернутися до Принципала за поясненнями.
        </Typography>
        <Typography variant="body2" paragraph>
          8.5. Покупець підтверджує і згоден з тим, що порядок і підстави
          повернення Номінальної ціни Квитка, а також особа, зобов'язана
          здійснювати таке повернення, перед ним доведені в повному обсязі, він
          знає і розуміє.
        </Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant="body1" paragraph>
          Стаття 9. ПРАВА І ОБОВ'ЯЗКИ СТОРІН
        </Typography>
        <Typography variant="body2" paragraph>
          9.1. Покупець має право:
        </Typography>
        <Typography variant="body2" paragraph>
          9.1.1. Самостійно з відкритих джерел отримувати інформацію про
          вартість Квитків на Заходи та розмір Сервісного збору, інших офіційних
          білетних операторів і приймати рішення про придбання Квитків у Агента.
        </Typography>
        <Typography variant="body2" paragraph>
          9.1.2. Отримувати інформацію про правила продажу Квитків, наявні
          категорії Квитків та іншу інформацію щодо Заходів, якою володіє Агент
          у межах своїх повноважень, з використанням Інтернету, дзвінка Агенту
          та іншими способами, зазначеними на інтернет-сайтах Агента:
          <a
            href="http://toptickets.com.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://toptickets.com.ua/
          </a>
          .
        </Typography>
        <Typography variant="body2" paragraph>
          9.1.3. Обирати спосіб оплати Квитка з пропозицій, що надаються Агентом
          на інтернет-сайтах Агента:{' '}
          <a
            href="http://toptickets.com.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://toptickets.com.ua/
          </a>
          . При цьому Покупець зобов'язаний самостійно ознайомитися з правилами
          використання певного способу оплати.
        </Typography>
        <Typography variant="body2" paragraph>
          9.1.4. Надсилати відгуки про роботу Агента за контактами Агента.
        </Typography>
        <Typography variant="body2" paragraph>
          9.2. Покупець зобов'язаний:
        </Typography>
        <Typography variant="body2" paragraph>
          9.2.1. Детально ознайомитися з усіма правилами цієї Оферти і прийняти
          їх при здійсненні покупки Квитка, а також всіма додатковими правилами,
          що регулюють відносини Сторін за цією Офертою.
        </Typography>
        <Typography variant="body2" paragraph>
          9.2.2. При оформленні Замовлення надати необхідну вірну, точну і повну
          інформацію про Покупця. Покупець несе відповідальність за
          неповнолітніх дітей, в інтересах яких він придбаває Квитки.
        </Typography>
        <Typography variant="body2" paragraph>
          9.2.3. Покупець визнає, що він несе повну відповідальність за дані,
          які повідомляються Агенту (його працівникам). Покупець визнає, що не
          має жодних претензій до Агента і його працівників щодо некоректно
          оформленого ним самостійно Замовлення, так як він сам не переконався в
          його коректності. Агент не несе відповідальності за відсутність
          можливості надання послуг і продажу Квитків на умовах, відмінних від
          викладених у цій Оферті.
        </Typography>
        <Typography variant="body2" paragraph>
          9.2.4. Оплатити повну вартість послуг Агента.
        </Typography>
        <Typography variant="body2" paragraph>
          9.2.5. Не розміщувати, не публікувати, не передавати і не поширювати
          повідомлення, які можуть мати злочинний характер або завдати будь-якої
          шкоди Агенту, Принципалу, Організатору.
        </Typography>
        <Typography variant="body2" paragraph>
          9.2.6. Не публікувати фото/зображення квитка в інтернеті та соціальних
          мережах.
        </Typography>
        <Typography variant="body2" paragraph>
          9.3. Агент має право:
        </Typography>
        <Typography variant="body2" paragraph>
          9.3.1. Вимагати від Покупця дотримання всіх процедур оформлення та
          оплати Замовлення згідно правил, викладених у цій Оферті.
        </Typography>
        <Typography variant="body2" paragraph>
          9.3.2. У разі відмови Покупця прийняти цю Оферту за умовами,
          зазначеними в ній, відмовити Покупцю в продажу йому Квитка та наданні
          послуг.
        </Typography>
        <Typography variant="body2" paragraph>
          9.3.3. Агент не несе відповідальності за нероботоздатність каналів
          зв'язку, за допомогою яких Покупець здійснює зв'язок з Агентом (його
          офіційним представником).
        </Typography>
        <Typography variant="body2" paragraph>
          9.3.4. Виконувати модифікацію будь-якого програмного забезпечення
          інтернет-сайтів Агента:
          <a
            href="http://toptickets.com.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://toptickets.com.ua/
          </a>
          , призупиняти роботу програмних засобів, що забезпечують
          функціонування інтернет-сайтів, у разі виявлення значних
          несправностей, помилок і збоїв, а також для проведення профілактичних
          робіт і запобігання випадкам несанкціонованого доступу до
          інтернет-сайтів.
        </Typography>
        <Typography variant="body2" paragraph>
          9.3.5. Встановлювати та змінювати тарифи на свої послуги в
          односторонньому порядку і в будь-який час.
        </Typography>
        <Typography variant="body2" paragraph>
          9.3.6. Включати до вартості Замовлення і стягувати з Покупця вартість
          Сервісного збору. Вартість доставки Покупцем оплачується окремо.
        </Typography>
        <Typography variant="body2" paragraph>
          9.3.7. Вимагати від Покупця повної оплати Квитка та послуг перед тим,
          як здійснити продаж та видачу Квитка на руки.
        </Typography>
        <Typography variant="body2" paragraph>
          9.3.8. У разі невиконання Покупцем будь-якого з пунктів Оферти
          відмовити йому у подальшому в наданні послуг.
        </Typography>
        <Typography variant="body2" paragraph>
          9.4. Агент зобов'язаний:
        </Typography>
        <Typography variant="body2" paragraph>
          9.4.1. Надавати Покупцеві необхідну інформацію про конкретну Подію.
        </Typography>
        <Typography variant="body2" paragraph>
          9.4.2. Надати Покупцеві інструкції для оформлення/оплати/отримання
          Квитка. Вичерпною інформацією вважається та інформація, яку Агент
          публікує на інтернет-сайтах Агента:
          <a
            href="http://toptickets.com.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://toptickets.com.ua/
          </a>
          , та надає в Точках продажу.
        </Typography>
        <Typography variant="body2" paragraph>
          9.4.3. Не використовувати персональні дані Покупця для розсилки
          матеріалів, які не мають відношення до послуг, які надаються Агентом.
        </Typography>
        <Typography variant="body2" paragraph>
          9.4.4. Не передавати персональні дані третім особам та не
          використовувати їх в інших цілях.
        </Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant="body1" paragraph>
          Стаття 10. ВІДПОВІДАЛЬНІСТЬ
        </Typography>
        <Typography variant="body2" paragraph>
          10.1. Агент не несе відповідальності за перенесення, заміну або
          скасування Заходу. Агент не є стороною, а також не несе обов'язки за
          угодою, укладеною між Принципалом та Покупцем щодо придбання Білета
          останнім.
        </Typography>
        <Typography variant="body2" paragraph>
          10.2. У разі невиконання або неналежного виконання своїх зобов'язань
          згідно з Офертою, Сторони несуть відповідальність відповідно до
          законодавства України та умов цієї Оферти. Відповідальність Агента в
          процесі надання Послуг Покупцю обмежується функцією організації та
          здійснення взаємодії між Принципалом та Покупцем з метою реалізації
          Білета, тобто Послугами, наданими безпосередньо Агентом.
        </Typography>
        <Typography variant="body2" paragraph>
          10.3. Агент не несе відповідальності за несумісність послуг, наданих
          Принципалом, з очікуваннями Покупця та/або його суб'єктивною оцінкою.
          Рекомендації та поради, що надаються Покупцю, не можуть розглядатися
          як гарантії.
        </Typography>
        <Typography variant="body2" paragraph>
          10.4. Покупець несе всі можливі комерційні ризики, пов'язані з його
          діями, які призводять до помилок та неточностей у наданих ним
          особистих даних.
        </Typography>
        <Typography variant="body2" paragraph>
          10.5. Агент не несе відповідальності за будь-які збитки та моральну
          шкоду, завдані Покупцю в результаті помилкового розуміння або
          незрозуміння інформації щодо порядку оформлення/оплати Замовлення, а
          також отримання та використання Послуг.
        </Typography>
        <Typography variant="body2" paragraph>
          10.6. Сторони звільняються від відповідальності за повне або часткове
          невиконання своїх зобов'язань за Офертою у разі, коли таке невиконання
          було наслідком непереборних обставин, тобто надзвичайних і
          непередбачуваних обставин за даних умов. До непереборних обставин,
          зокрема, відносяться: стихійні лиха, воєнні дії, страйки, дії та
          рішення державних органів влади, виникнення збоїв у телекомунікаційних
          та енергетичних мережах.
        </Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant="body1" paragraph>
          Стаття 11. ОБМЕЖЕННЯ ВІДПОВІДАЛЬНОСТІ
        </Typography>
        <Typography variant="body2" paragraph>
          11.1. Ні при яких обставинах Агент, його працівники, директори,
          посадові особи або інші пов'язані сторони, спонсори, посередники,
          представники, партнери або будь-які інші особи, в тому числі діючі від
          імені Агента, не несуть відповідальності за будь-які прямі або непрямі
          збитки, виниклі в результаті продажу Білета або надання Послуг, а
          також внаслідок несанкціонованого доступу до особистих даних Покупця,
          включаючи упущену вигоду.
        </Typography>
        <Typography variant="body2" paragraph>
          11.2. Всі претензії, пов'язані з договором, укладеним за умовами цієї
          Оферти, повинні бути подані у письмовій формі протягом десяти днів з
          дати їх виникнення. Претензії, подані після цього терміну, не
          розглядаються Агентом.
        </Typography>

        <Typography variant="body1" paragraph>
          Стаття 12. ВИРІШЕННЯ СПОРІВ
        </Typography>
        <Typography variant="body2" paragraph>
          12.1. Спори, що виникають у процесі виконання договору за умовами цієї
          Оферти, вирішуються з дотриманням претензійного порядку. Претензія
          подається Агенту у письмовій формі з додаванням документів, що
          обґрунтовують висунуті вимоги, у строк не більше 10 (десяти)
          календарних днів з дати виникнення причини спору. Отримана претензія
          розглядається Агентом протягом строку не більше 10 (десяти)
          календарних днів.
        </Typography>
        <Typography variant="body2" paragraph>
          12.2. У випадку неможливості досягнення згоди, виниклі спори
          підлягають розгляду в суді за місцезнаходженням Агента.
        </Typography>
        <Typography variant="body2" paragraph>
          12.3. З усіх інших питань, що не передбачені Офертою, Сторони
          керуються законодавством України.
        </Typography>

        <Typography variant="body1" paragraph>
          Стаття 13. ЗМІНА І ПРИПИНЕННЯ ОФЕРТИ
        </Typography>
        <Typography variant="body2" paragraph>
          13.1. Ця Оферта набуває чинності з дати її розміщення на сайті Агента
          і діє безстроково. Положення цього пункту поширюються і на доповнення
          (зміни) до цієї Оферти.
        </Typography>
      </Box>

      {/* Remaining sections */}
    </Container>
  );
};

export default OfferPage;
