// path: src/page/About/AboutPage.js

import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about-page">
      <h1>Про нас</h1>
      <p>
        Ласкаво просимо на TopTicket — ваш надійний партнер у світі яскравих
        вражень та незабутніх подій! Ми допомагаємо вам відкрити двері до
        музичних концертів, театральних вистав, спортивних заходів, фестивалів і
        багато чого іншого, що приносить радість та натхнення.
      </p>
      <p>
        Наша місія — забезпечити простий та зручний доступ до квитків на
        найкращі події. На TopTicket ви знайдете не лише широкий вибір заходів
        на будь-який смак, а й можливість купувати квитки швидко, безпечно та
        вигідно.
      </p>
      <h2>Що ми пропонуємо:</h2>
      <ul>
        <li>
          <strong>Зручність.</strong> Уся інформація про події на одному сайті,
          щоб ви могли знайти потрібне за кілька кліків.
        </li>
        <li>
          <strong>Надійність.</strong> Ми працюємо тільки з офіційними
          партнерами та організаторами, забезпечуючи справжність кожного квитка.
        </li>
        <li>
          <strong>Простота покупки.</strong> Забудьте про черги та незручності —
          купуйте квитки онлайн у зручний для вас час.
        </li>
        <li>
          <strong>Підтримка 24/7.</strong> Ми завжди готові допомогти вам із
          будь-якими питаннями чи технічними складнощами.
        </li>
      </ul>
      <p>
        З нами ви зможете бути впевнені, що жодна подія не пройде повз вас. Ми
        працюємо для вас, щоб дарувати яскраві спогади та незабутні емоції разом
        з TopTicket.
      </p>
      <p>Приєднуйтесь до нашої спільноти!</p>
    </div>
  );
};

export default AboutPage;
